.footer {
  padding-top: rem(260px);
  position: relative;
  z-index: 2;
  color:cl(white);
  font-size: rem(15px);
  line-height: rem(24px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  a{
    color: cl(white);
  }
  &-social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include media-breakpoint-down(xs) {
      justify-content: center;
      margin-bottom: 1rem;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      @include circle(30px);
      color:cl(white);
      margin-left: rem(20px);
      border:rem(1px solid transpatenr);
      @include media-breakpoint-down(xs) {
        @include circle(40px);
        margin: 0 1rem;
      }
      &:hover {
        text-decoration: none;
        border:rem(1px solid cl(white));
      }
      i {
        font-size: rem(16px);
        @include media-breakpoint-down(xs) {
          font-size: rem(24px);
        }
      }
    }
  }
  &-logo {
    display: block;
    margin: 0 auto;
    width:rem(170px);
    margin-top: rem(-65px);
    @include media-breakpoint-down(xs) {
      margin-bottom: 2rem;
    }
    img {
      width:100%;
      height: auto;
    }
  }
  address{
    margin-bottom: 1rem;
    @include media-breakpoint-down(xs) {
      margin-bottom: 0.5rem;
    }
  }
  &-contacts {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: rem(15px);
    line-height: rem(25px);
    margin-bottom: 0;
    @include media-breakpoint-down(xs) {
      font-size: rem(16px);
    }
    @media (max-width: 500px){
      justify-content: center;
    }
    dt {
      color:cl(primary);
      font-size: rem(16px);
      line-height: rem(26px);
      width:rem(30px);
    }
    dd {
      margin: 0;
      a {
        color:cl(primary);
      }
    }
  }
  &-copy {
    font-size: rem(14px);
    font-family:$font;
    @include media-breakpoint-down(xs) {
      text-align: center;
      margin: 0 auto;
      display: block;
    }
  }
  &--top-line{
    padding: rem(39px 0);
    background-color: cl(secondary,0.9);
    &-inner{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media-breakpoint-down(xs) {
       flex-wrap: wrap;
      }
      .first-col{
        width: calc(50% - 6rem);
        flex-shrink: 0;
        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;
        }
        @include media-breakpoint-down(xs) {
          width: 60%;
          order: 1;
        }
        @media (max-width: 500px){
          width: 100%;
          margin-bottom: 1rem;
          text-align: center;
        }
        .link{
          @include media-breakpoint-down(xs) {
            margin-bottom: 1rem;
            display: block;
          }
        }
      }
      .second-col{
        width:12rem;
        display: flex;
        flex-shrink: 0;
        align-self: flex-start;

        justify-content: center;
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }
      .third-col{
        flex-shrink: 0;
        width: calc(50% - 6rem);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include media-breakpoint-down(md) {
          flex-direction: column;
          align-items: flex-end;
        }
        @include media-breakpoint-down(xs) {

          width: 40%;
          order: 1;
        }
        @media (max-width: 500px){
         width: 100%;
          justify-content: center;
        }
        .out-link{
          max-width: 29%;
          width: rem(110px);
          margin-left: 4%;
          @include media-breakpoint-down(md) {
            max-width: 59%;
           // width: rem(70px);
            margin-bottom: 1rem;
          }
          @include media-breakpoint-down(sm) {
            width: 60%;
            margin-bottom: 1rem;
          }
          @media (max-width: 500px){
            margin: 0 auto 1rem;
            width: rem(150px);
            max-width: 100%;
          }
          img{
            width: 100%;
          }

        }
      }
    }
    .email{
      margin-bottom: rem(10px);
    }
    ul{
    list-style: none;
         }
  }
  &--bottom-line{
    padding: rem(6px 15px);
    background-color: cl(primary);
    color: cl(white);
    img{
      height:rem(21px);
    }
  }
}


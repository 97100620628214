.home--calendar-section{
  padding: rem(40px 0 20px 0);
  position: relative;
  background-size: 100% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
  text-align: center;
  iframe{
    position: relative;
    z-index: 5;
  }
  .calendly-inline-widget{
    max-width: 90vw;

    height: 670px!important;
    @include media-breakpoint-down(md) {
      height: 1000px!important;
    }
  }
  .btn{
    margin-top: rem(20px);
    margin-bottom:rem(30px);
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
  .w-wrap{
    margin-top: -60px;
    position: relative;
    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
    .container{
      position: relative;
      margin: 0 auto;
    }
    .deco{
      width: rem(220px);
      right: rem(-220px);
      top: rem(-40px);
      position: absolute;
      @media (max-width: 1320.98px){
        right: -20.75rem;
      }
      @media (min-width: 1720.98px){
        right: -15%;
      }
      @media (min-width: 1920.98px){
        right: -7%;
      }
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}
.home--info-section{
  padding: rem(55px 0);
  position: relative;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  .btns-holder{
    column-gap: rem(25px);
    display: flex;
    justify-content: center;
    align-self: center;
    flex-wrap: wrap;
    margin-bottom: rem(30px);
    .btn{
      @include media-breakpoint-down(xs) {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }
  .information-block{
    display: flex;
    justify-content: space-between;
    align-self: flex-start;
    font-size: rem(18px);
    flex-wrap: wrap;
    .text-side{
      width: 64%;
      padding-right: 5vw;
      @include media-breakpoint-down(md) {
        width: 100%;
        padding-right: 0;
      }
    }
    .image-side{
      width: 36%;
      padding-right: rem(46px);
      @include media-breakpoint-down(md) {
        width: 100%;
        padding-right: unset;
      }
      .image-holder{
        width: rem(450px);
        height: rem(280px);
        position: relative;
        float: right;
        @include media-breakpoint-down(lg) {
          width: rem(400px);
          height: rem(280px);
        }
        @include media-breakpoint-down(md) {
         float: none;
          margin: 0 auto 2rem;
          width: rem(450px);
          height: rem(280px);
        }
        @include media-breakpoint-down(xs) {
          width: 90vw;
          height:53vw;
         }
        .logo{
          opacity: 0.18;
          width: 45%;
          top: 0;
          left: 8%;
          position: absolute;
          z-index: 0;
          @include media-breakpoint-down(lg) {
            left: 12%;
          }
          @include media-breakpoint-down(md) {
            left: 8%;
          }
        }
        .letters{
          position: absolute;
          left: 0;
          top: 18%;
          width: 35.5%;
          z-index: 1;
          @include media-breakpoint-down(lg) {
            left: 10px;
          }
          @include media-breakpoint-down(md) {
            left: 0;
          }
        }
        .text-block{
          border: 1px solid cl(secondary);
          box-shadow: -0.707px 0.707px 16px 0px rgba(0, 0, 0, 0.09);
          border-radius: rem(8px);
          background-color: rgb(255, 255, 255);
          min-height: rem(70px);
          padding: rem(11px 21px);
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: rem(18px);
          line-height: rem(25px);
          font-weight: 700;
          text-align: center;
          @extend .ttu;
          min-width: rem(130px);
          min-width: 32%;
          @include media-breakpoint-down(lg) {
            font-size: rem(15px);
            line-height: rem(22px);
          }
          @include media-breakpoint-down(xs) {
            font-size: 3.7vw;
            font-size: 2.5vw;
            line-height: 1.3;
            min-height: 14vw;
          }
          &-1{
            right: 0;
            top:5%;
            z-index: 2;
          }
          &-2{
            left: 40%;
            top:27%;
            z-index: 3;
          }
          &-3{
            right: 0;
            top:48%;
            z-index: 2;
          }
          &-4{
            left: 40%;
            top:69%;
            z-index: 3;
          }
        }
      }
    }
  }
}

.home--about-section{
  padding: rem(55px 0);
  background: left bottom/cover , no-repeat, cl(secondary);
  position: relative;
  color:cl(white);
  .about-block{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .text-side{
      width: 27%;
      @include media-breakpoint-down(md) {
        width: 100%;
        padding-right: unset;
        order: 1;
      }
    }
    .image-side{

      width: 73%;
      @include media-breakpoint-down(md) {
        width: 100%;
        padding-right: unset;
      }
      .image-holder{
       // width: rem(750px);
        width: 39vw;
        //height: rem(450px);
        height: 23.43vw;
        position: relative;
        float: right;
        @include media-breakpoint-down(lg) {
          float: unset;
          margin: 0 auto;
        }
        @include media-breakpoint-down(md) {
          float: unset;
          margin: 0 auto;
          width: 90vw;
          height: 54vw;
        }
        .logo{
          position: absolute;
          z-index: 2;
          width: 36%;
          &-left{
            left: -8%;
            bottom: -1%;
          }
          &-right{
            right: -7%;
            bottom: 0;
          }
        }
        .photo{
          position: absolute;
          z-index: 1;
          background: center/cover , no-repeat, cl(primary);
          box-shadow: -1px 19px 62px 0px rgba(0, 0, 0, 0.09);
          &-1{
            width: 40%;
            height: 53%;
            top:0;
            left: 9.7%;
          }
          &-2{
            top:0;
            left: 51.7%;
            width: 47.7%;
            height: 45.5%;
          }
          &-3{
            top:56%;
            width: 26.4%;
            height: 42.2%;
            left:23.3%;
          }
          &-4{
            top:48.5%;
            width: 25.3%;
            height: 42.2%;
            left: 51.7%;
          }
        }
      }
    }
  }
}

.home--news-events-section{
  padding: rem(63px 0 115px 0);
  background: center bottom/cover , no-repeat;
  position: relative;
  h4{
    @extend .ttu;
  }
  .sec-wrap{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    @include media-breakpoint-down(md) {flex-wrap: wrap}
    .events-side{
      width: 26.5%;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 2rem;
      }
      .events-block{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
      .box{
        border-radius: rem(8px);
        background-color: rgb(255, 255, 255);
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        width: 100%;
        flex-grow: 1;
        padding: rem(40px 25px 25px 25px);
        font-size: rem(16px);
        line-height: rem(26px);
        position: relative;
        margin-top: rem(20px);
        .ttl{
          font-weight: 700;
          font-size: rem(20px);
          line-height: rem(30px);
          margin-bottom: rem(10px);
        }
        .event-date{
          border-radius: rem(4px);
          background-color:cl(secondary);
          min-width: rem(69px);
          height: rem(45px);
          @extend .ttu;
          position: absolute;
          left: rem(25px);
          top: rem(-14px);
          font-size: rem(16px);
          line-height: rem(26px);
          color: cl(white);
          display: flex;
          justify-content: center;
          align-items: flex-end;
          padding: rem(8px 15px);
          span{
            font-size: rem(30px);
          }
        }
        img{
          margin-top: rem(20px);
          width: 100%;
          max-height: rem(220px);
          object-fit: contain;
          object-position: center top;
        }
      }
      .btn-wrap-single {
        margin-top: rem(14px);
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        .btn{
          width: 100%;
        }
      }
      .btns-wrap{
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        margin-top: rem(14px);
        position: relative;
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        &:before{
          content:'';
          width: 1px;
          height: 100%;
          left: 49.7%;
          top:0;
          background-color: #b66c65;
          z-index: 10;
          position: absolute;
        }
        .btn{
          width: 50%;
          &:first-child{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            &:before,
            &:after{
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
          &:last-child{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            &:before,
            &:after{
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }
    }
    .news-side{
      width: 63%;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      h4{
        text-align: right;
      }
      .btn{
        width: 100%;
        margin-top: rem(4px);
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
      }
      .news-block{
        flex-grow: 1;
        margin-top: rem(20px);
      }
      .news-item{
        border-radius: rem(8px);
        background-color: rgb(255, 255, 255);
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        width: 100%;
        font-size: rem(16px);
        line-height: rem(26px);
        padding: rem(15px 30px);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: rem(10px);
        @include media-breakpoint-down(sm) {
          flex-direction: column;
        }
        .news-item-inner{
          padding-right: rem(25px);
          max-height: rem(85px);
          overflow-y: hidden;
          @include media-breakpoint-down(sm) {
            order:1;
            padding-right: 0;
            max-height: unset;
          }
        }
        .ttl{
          font-weight: 700;
          font-size: rem(20px);
          line-height: rem(30px);
          margin-bottom: rem(16px);
        }
        .news-item-img{
          flex-shrink: 0;
          @include media-breakpoint-down(sm) {
            margin: 0 auto;
          }
        }
        img{
          object-fit: cover;
          width: rem(120px);
          height: rem(100px);
          object-position: center;

          @include media-breakpoint-down(sm) {
            margin: 0 auto 1rem;
            width: rem(240px);
            height: rem(200px);
          }
        }
        p{
          margin-bottom: 0;
        }
      }
    }
  }
}

.home--video-album-section{
  padding: rem(115px 0 100px 0);
  background-color: #0a2855;
  .block-wrap{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
    .video-side{
      width: 60.58%;
      position: relative;
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 4rem;
      }
      .video-holder{
        position: relative;
        width: 85.7%;
        margin-left: auto;
        padding-bottom: 52.7%;
        display: block;
        z-index: 8;
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        @include media-breakpoint-down(md) {
          margin: 0 auto;
          width: 100%;
          padding-bottom: 56.6%;
        }
        .poster-cover{
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          @extend .pos-center;
          z-index: 1;
        }
        video{
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          position: absolute;
          @extend .pos-center;
          &[poster]{
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        iframe{
          width: 100%!important;
          height: 100%!important;
          z-index: 0;
          position: absolute;
          @extend .pos-center;
        }
        &.active{
          .poster-cover{
            display: none;
          }
          .play{
            display: none;
          }
          &:hover{
            cursor: pointer;
            .pause{
              display: block;
            }
          }
        }
        &:hover{
          cursor: pointer;
          .play{
            img {
              width: rem(78px);
              height: rem(78px);
              @include media-breakpoint-down(md) {
                width: rem(58px);
                height: rem(58px);
              }
            }
          }
        }
        .play{

          position: absolute;
          @extend .pos-center;
          z-index: 4;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          transition: 0.5s ease-in-out;
          transform-origin: center;
          display: flex;
          justify-content: center;
          align-items: center;

          img{
            width: rem(70px);
            height: rem(70px);
            position: relative;
            z-index: 4;
            @include media-breakpoint-down(md) {
              width: rem(50px);
              height: rem(50px);
            }
          }
        }
      }
      .links-block{
        border-radius: rem(8px);
        background-color: rgb(255, 255, 255);
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        position: absolute;
        width: rem(200px);
        height: rem(230px);
        z-index: 9;
        top: rem(-27px);
        left: 0;
        padding: rem(35px 25px);
        @include media-breakpoint-down(md) {
          width: 93%;
          margin: 0 auto -1rem;
          height: auto;
          position: relative;
          top: unset;
          left: unset;

        }
        .main-link{
          font-size: rem(20px);
          color: cl(secondary);
          font-weight: bold;
          text-transform: uppercase;
          line-height: 1.25;
          margin-bottom: rem(20px);
          display: block;
        }
        .second-link{
          color: cl(primary);
          font-size: rem(20px);
          font-weight: bold;
          line-height: 1.25;
          display: block;
        }
      }
    }
    .album-side{
      width: 29%;
      position: relative;
      @include media-breakpoint-down(md) {
        width: 100%;

      }
      .album-link{
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        position: relative;
        display: block;
        width: 100%;
        padding-bottom: 110%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        font-size: rem(20px);
        font-weight: bold;
        line-height: 1.5;
        &:after{
          content: '';
          background-size: 100% auto;
          background-position: center bottom;
          background-repeat: no-repeat;
          background-image: url("../img/shadow.png");
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
        }
        .name{
          color: rgb(255, 255, 255);
          text-align: center;
          position: absolute;
          @extend .pos-centerX;
          bottom: 1rem;
          z-index: 4;
        }
        .ttl{
          border-radius: rem(8px);
          background-color: rgb(255, 255, 255);
          box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
          position: absolute;
          width: 93%;
          padding: rem(13px);
          top: rem(-27px);
          z-index: 3;
          color:cl(secondary);
          @extend .pos-centerX;
          @extend .ttu;
          text-align: center;
        }
      }
    }
  }

}

.best-teacher-section{
  padding: rem(50px 0 60px 0);
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  .teacher-ttl-wrap{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .teacher-ttl{
    padding: rem(77px 0 50px 0);
    font-size: rem(30px);
    line-height: rem(30px);
    font-weight: 700;
    color: cl(secondary);
    @extend .ttu;
    position: relative;
    padding-right: 5.4vw;
    margin-bottom: 1rem;
    @include media-breakpoint-down(md) {
      width: 100%;
      order: 0;
      justify-content: center;
      align-items: center;
      display: flex;
      // margin-bottom: 2rem;
    }

    span{
      z-index: 3;
      position: relative;
      display: inline-block;
    }
    .logo{
      position: absolute;
      right: 0;
      width: 12vw;
      opacity: 0.18;
      @extend .pos-centerY;
      @include media-breakpoint-down(md) {
        left: 60%;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .stars-top{
      position: absolute;
      left: 0;
      width: auto;
      height: rem(70px);
      top:0;
      @include media-breakpoint-down(md) {
        left: 45%;
        transform: translateX(-50%);
      }
    }
    .stars-bottom{
      position: absolute;
      left: 0;
      width: auto;
      height: rem(50px);
      bottom:0;
      @include media-breakpoint-down(md) {
        left: 45%;
        transform: translateX(-50%);
      }
    }
  }
  .teachers-block{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
    flex-wrap: wrap;
      gap: 3rem;
    }
    @include media-breakpoint-down(xs) {
      flex-direction: column;

    }

    .teacher-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      @include media-breakpoint-down(md) {
        order: 1;
      }
      @include media-breakpoint-down(xs) {
        width: 90%;
      }
      @media (max-width: 375.98px){
        width: 100%;
      }
      &:first-child{
        .item-inner{
          .name{
            background-color: cl(secondary);
          }
        }
      }
      .sub-ttl{
        font-size: rem(20px);
        font-weight: 700;
        color: cl(primary);
      }
      .item-inner{
        padding: rem(22px);
        border-style: solid;
        border-width: rem(8px);
        border-color: rgb(10, 40, 85);
        background-color: rgb(255, 255, 255);
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        width: rem(330px);
        margin-bottom: rem(24px);
        @include media-breakpoint-down(sm) {
          border-width: rem(6px);
          padding: rem(15px);
        }
        @include media-breakpoint-down(xs) {
          width: 100%;
          border-width: rem(6px);
          padding: rem(15px);
        }
        .avatar{
          width: 100%;
          padding-bottom: 88%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          margin-bottom: rem(16px);
        }
        .name{
          font-size: rem(15px);
          font-weight: 700;
          color: cl(white);
          text-align: center;
          background-color: cl(primary);
         // padding: rem(14px);
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: rem(48px);
          line-height: 1.2;
          padding: 0 1rem;
          &:before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            border-left: 0.875rem solid #ffffff;
            border-top: 1.5rem solid transparent;
            border-bottom: 1.5rem solid transparent;
            width: 0;
            height: 0;
            content: " ";
          }
          &:after {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            border-right: 0.875rem solid #ffffff;
            border-top: 1.5rem solid transparent;
            border-bottom: 1.5rem solid transparent;
            width: 0;
            height: 0;
            content: " ";
          }
        }
      }

    }
  }
}
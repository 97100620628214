.btn {

  color:cl(white)!important;
  font-size: rem(20px);
  font-family: $font;
  font-weight: 700;
  line-height: rem(48px);
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  border: 2px solid cl(secondary);
  cursor: pointer;
  border-radius: rem(8px);
  padding: rem(0 25px);
  outline: 0;
  white-space: nowrap;
  letter-spacing: 0.1em;
  transition: 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  width: max-content;
  min-width: rem(150px);
  z-index: 3;
  @include media-breakpoint-down(sm) {
    white-space: normal;
    width: auto;
    max-width: 100%;
  }
  &:before {
    -webkit-transition:0.5s all ease;
    transition:0.5s all ease;
    position:absolute;
    top:-1px;
    left:0;
    width: 100%;
    bottom:-1px;
    opacity:1;
    content:'';
    background-color:cl(primary);
    z-index:-1;
    border-radius: rem(8px);
  }
  &:after{
    -webkit-transition:0.5s all ease;
    transition:0.5s all ease;
    position:absolute;
    top:-1px;
    left:0;
    width: 100%;
    bottom:-1px;
    opacity:1;
    content:'';
    background-color:cl(secondary);
    z-index:-2;
    border-radius: rem(8px);
  }

  &:hover,
  &:focus{
    color: cl(white) !important;
    &:before {
      -webkit-transition:0.5s all ease;
      transition:0.4s all ease;
      opacity:0;
      width: 0;
    }
  }
  &:focus {
    box-shadow:none;
    outline: none;
  }

  &--secondary{
    border: 2px solid cl(primary);

    &:before {
      background-color:cl(secondary);
      left: unset;
      right: 0;
    }
    &:after{
      background-color:cl(primary);
    }
  }
  &--white{
    border: 2px solid cl(primary);
    color:cl(primary)!important;
    &:before {
      background-color:cl(white);
      left: unset;
      right: 0;
    }
    &:after{
      background-color:cl(primary);
    }
    &:hover{
      color: cl(white)!important;
    }
  }
  &--secondary-border{
    border: 2px solid cl(secondary);

    &:before {
      background-color:cl(secondary);
      left: unset;
      right: 0;
    }
    &:after{
      background-color:cl(primary);
    }
    &:hover{
      border: 2px solid cl(primary);
    }
  }
  &--small{
    font-weight: 400;
    font-size: rem(16px);
    letter-spacing: 0;
    i{
      font-size: rem(12px);
    }
  }
}




.floating-btn {
  top: 50%;
  right: rem(-65px);
  transform: translateY(-50%) rotate(-90deg);
  position: fixed;
  border-radius: 1.25rem 1.25rem 0 0;
  z-index: 100;
  color: cl(primary);
  font-size: rem(18px);
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  box-shadow: 0.942px -11.984px 54px 0px rgba(0, 0, 0, 0.21);
  background-color: cl(white);
  font-weight: 600;
  width: rem(170px);
  height: rem(40px);
  transition: all 0.2s linear;
  @include media-breakpoint-down(lg){
    display: none;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    padding: 0;
    right: 0.5rem;
    bottom: 1.5rem;
    top: unset;
    transform: none;
    background-color: cl(primary);
    box-shadow: 0.837px 11.971px 54px 0px rgba(0, 0, 0, 0.21);
  }
  &:hover{
    text-decoration: none;
    color: cl(white);
    background-color: cl(primary);
  }
  span{
    @include media-breakpoint-down(lg){
      display: none;
    }
  }
  .mobile-icon{
    display: none;
    @include media-breakpoint-down(lg){
      display: block;
      width: 60%;
      object-fit: contain;
    }

  }
}
/* banner start */
.banner {
  position: relative;
  background: center/cover no-repeat, cl(primary, 0.5);
  color: cl(white);
  margin-top: rem(75px);
  &--home {
    height: rem(500px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include media-breakpoint-down(md) {
      height: rem(350px);
    }
    .float-more-btns{
      position: fixed;
      right: 0;
      bottom: 20%;
      z-index: 20;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @include media-breakpoint-down(md) {
        display: none;

      }
      a{
        background-color: cl(secondary);
        color: cl(white);
        @extend .ttu;
        padding:rem(10px);
        font-size: rem(16px);
        font-weight: 700;
        margin-bottom: 0.25rem;
        text-align: center;
        min-width: rem(142px);
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        &:hover{
          background-color: cl(primary);
          text-decoration: none;
        }
        &:first-child{
          min-width: rem(153px);
        }
      }
    }
  }
  &--page {
    height: rem(300px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: rem(30px 0 30px 0);
    background:left /cover no-repeat, cl(secondary);
    color: cl(white);
    margin-top: rem(70px);
    .page-ttl{
      font-size: rem(60px);
      font-family: $font;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      line-height: 1;
      text-align: center;
      max-width: rem(1000px);
      font-weight: 700;
      @include media-breakpoint-down(md) {
        font-size: rem(50px);
      }
      @include media-breakpoint-down(sm) {
        font-size: rem(40px);
      }
      @include media-breakpoint-down(xs) {
        font-size: rem(35px);
      }
    }

  }
  &-video {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 0;
    &.banner-video-absolute{
      position: absolute;
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-text {
    position: relative;
    width:100%;
    height: 100%;
    color:cl(white);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    .ttl {
      font-size: rem(70px);
      font-family: $font;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      line-height: 1;
      text-align: center;
      text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.36);
    }
    p {
      margin: 0;
    }
  }

}
/* banner end */


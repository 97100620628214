.content-flex{
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: row-reverse;
  font-size: rem(18px);
  line-height: rem(28px);
  padding: rem(15px 0 50px 0);
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
  .left-menu{
    flex-shrink: 0;
    width: 14.5rem;
    margin-right: 3rem;
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-right:0;
      margin-bottom: 2rem;
    }

    ul{
      border-radius: rem(8px);
      background-color:cl(primary);
      box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
      position: relative;
      width: 100%;
      list-style: none;
      margin-bottom: 1rem;
      padding: rem(13px 0);
      @include media-breakpoint-down(md) {
        display: none;
      }
      li{
        color: cl(white);
        font-size: rem(16px);
        background-color: transparent;
        line-height: rem(24px);
        padding: rem(8px 20px 8px 24px);
        &.active{
          background-color:cl(secondary);

        }
        &:hover{
          background-color:cl(secondary,0.6);
        }
        a{
          color: cl(white);
          display: block;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }

  }
  .main-part{
    //width: calc(100% - 16.75rem);
    flex-grow: 1;
    width: auto;
    font-size: rem(18px);
    color: cl(primary);
    line-height: rem(28px);
    @include media-breakpoint-down(md) {
      width: 100%;
      order: 1;
    }
    .frame-wrap{
      margin-top: -3rem;
      max-width: 90vw;
      height: 670px!important;
      @include media-breakpoint-down(md) {
        height: 1000px!important;
      }
    }
    p{
      margin-bottom: rem(20px);
    }
    a{
      color: cl(secondary);
    }
    .more-text{
      img{
        max-width: 80%;
        max-height: 70vh;
        object-fit: contain;
        display: block;
        margin: 0 auto 1.5rem;
        object-position: center top;
      }
      .video-holder{
        width: 85%;
        margin: 0 auto 2rem;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
        video{
          width: 100%;
          object-fit: cover;
          box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        }
      }
      &.news-inner{
        a{
          color: cl(primary);
        }
        .btns-wrap{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .btn{
            margin-right: 3rem;
            margin-top: 2rem;
            @include media-breakpoint-down(sm) {
              width: 100%;
              margin-right: 0;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
    .first-block{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 1.5rem;
      justify-content: space-between;
        .left-side{
          width: calc(100% - 28.125rem);
          padding-right:rem(50px);
          @include media-breakpoint-down(md) {
            width: 100%;
            padding-right: unset;
            order: 1;
          }
        }
        .image-side{
          width: rem(450px);
          @include media-breakpoint-down(md) {
            width: 100%;
            padding-right: unset;

          }
          .image-holder{
            width: rem(450px);
            height: rem(280px);
            position: relative;
            float: right;
            overflow: hidden;
            @include media-breakpoint-down(md) {
              float: none;
              margin: 0 auto 2rem;
            }
            @include media-breakpoint-down(xs) {
              width: 90vw;
              height:53vw;
            }
            .logo{
              opacity: 0.18;
              width: 45%;
              top: 0;
              left: 8%;
              position: absolute;
              z-index: 0;
            }
            .letters{
              position: absolute;
              left: 0;
              top: 18%;
              width: 35.5%;
              z-index: 1;
            }
            .text-block{
              border: 1px solid cl(secondary);
              box-shadow: -0.707px 0.707px 16px 0px rgba(0, 0, 0, 0.09);
              border-radius: rem(8px);
              background-color: rgb(255, 255, 255);
              min-height: rem(70px);
              padding: rem(11px 21px);
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: rem(18px);
              line-height: rem(25px);
              font-weight: 700;
              text-align: center;
              @extend .ttu;
              min-width: rem(130px);
              min-width: 32%;
              @include media-breakpoint-down(lg) {
                font-size: rem(15px);
                line-height: rem(22px);
              }
              @include media-breakpoint-down(xs) {
                font-size: 3.7vw;
                font-size: 2.5vw;
                min-height: 14vw;
              }
              &-1{
                right: 0;
                top:5%;
                z-index: 2;
              }
              &-2{
                left: 40%;
                top:27%;
                z-index: 3;
              }
              &-3{
                right: 0;
                top:48%;
                z-index: 2;
              }
              &-4{
                left: 40%;
                top:69%;
                z-index: 3;
              }
            }
          }

      }
    }
    .about-videos-block{
      display: flex;
      column-gap: 2rem;
      flex-wrap: wrap;
      .item{
        display: block;
        width: calc(33% - 1.2rem);
        font-size: rem(16px);
        line-height: rem(26px);
        color: cl(text);
        margin-bottom: 1.25rem;
        cursor: pointer;
        @include media-breakpoint-down(sm) {
          width: calc(50% - 1.2rem);
        }
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
        &:hover{
          color: cl(secondary);
          text-decoration: none;
          .image{
            .play{
              img{
                width: rem(63px);
                height: rem(63px);
              }
            }
          }
        }
        .image{
          position: relative;
          width: 100%;
          padding-bottom: 56.6%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
          margin-bottom: rem(14px);
          .play{
            position: absolute;
            @extend .pos-center;
            z-index: 4;
            width: 100%;
            height: 100%;
            transition: 0.5s ease-in-out;
            transform-origin: center;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: rem(53px);
              height: rem(53px);
              position: relative;
              z-index: 4;
              max-width: 80%;
              max-height: 70vh;
              -o-object-fit: contain;
              object-fit: contain;
              display: block;
              margin: unset;
              -o-object-position: center top;
              object-position: center center;
            }
          }
        }

        .upload-time{
          color: #7e7e7e;
          font-size: rem(13px);
        }
      }
    }
    .video-inner-page-block{
      margin-bottom: 4rem;
      img{
        max-width: 80%;
        max-height: 70vh;
        object-fit: contain;
        display: block;
        margin: 0 auto 1.5rem;
        object-position: center top;
      }
      .video-holder{
        width: 85%;
        margin: 0 auto 2rem;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
        video{
          width: 100%;
          object-fit: cover;
          box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        }
      }
      .ttl{
        font-size: rem(30px);
        line-height: 1.4;
        margin: rem(0 0 20px);
        font-family: $font;
        font-weight: 700;
        @include media-breakpoint-down(xs) {
          font-size: rem(25px);
        }
      }
      .upload-time{
        color: #7e7e7e;
        font-size: rem(16px);
      }
      .toggle-info-link{
        margin-left: 1rem;
        color: #7e7e7e;
        font-size: rem(16px);
        &:hover{
          color: cl(secondary);
        }

      }
      .share-toggle-block{
        margin-top: 1rem;
        padding: rem(15px);
        border-radius: rem(8px);
        border:1px solid #7e7e7e;
      }
    }
    .video-inner-page-bottom-block{
      margin-top: 2rem;
      padding-top: 1.25rem;
      border-top: 1px solid rgb(214, 214, 214);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .subscribe-v-block{
        ul{
          list-style: none;
          display: flex;
          li{
            margin-right: 1.5rem;
            a{
              font-size: rem(16px);
              line-height: 1.25rem;
              color: cl(primary);
              display: flex;
              align-items: center;
              justify-content: flex-start;
              img{
                width: 1.25rem;
                height: 1.25rem;
                object-fit: contain;
                margin: 0;
                margin-right: 0.3rem;
              }

            }
          }
        }
      }
    }
    .files-block{
      max-width: 100%;
      margin: 0 auto 2rem;
      .item-download-file{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgb(214, 214, 214);
        padding: rem(13px 23px);
        background-color: cl(white);
        margin-bottom: rem(12px);
        transition: 0.2s ease-in-out;
        font-size: rem(16px);
        font-family: $font;
        line-height: rem(25px);
        border-radius: rem(4px);
        @include media-breakpoint-down(md) {
          font-size: rem(16px);
          line-height: rem(26px);
          padding: rem(12px 15px);
        }
        &:hover{
          border: 1px solid cl(primary);
          text-decoration: none;
          color: cl(secondary);
          .name{
            color: cl(secondary);
          }
          .download-pic{
           color: cl(secondary);
          }
        }
        .text-cont{
          overflow-x: hidden;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          max-width: calc(100% - 3rem);
        }
        .file-img{
          height: rem(25px);

          margin: 0;
          margin-right: rem(25px);
          @include media-breakpoint-down(md) {
            height: rem(25px);
            margin-right: rem(15px);
          }
        }
        .name{
          color: cl(primary);
          white-space: nowrap;
          transition: 0.2s ease-in-out;
          font-weight: 700;
        }
        .download-pic{
          position: relative;
          color: cl(primary);

        }
      }
    }
  }
}

.search-block{
  position: relative;
  margin:rem(0 0 0 0);
  width: rem(290px);
  height: rem(38px);
  @include media-breakpoint-down(xs) {
    order: 1;
    width: 100%;
    margin: 1rem 0;
  }
  .sbm{
    @extend .pos-centerY;
    border:0;
    position: absolute;
    width: rem(36px);
    height: rem(36px);
    background-color: transparent;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem(0 10px);
    &:focus {
      outline: none;
      box-shadow:none;
      outline: dashed 1px rgb(214, 214, 214);
    }

    img{
      width: rem(20px);
      height: rem(20px);
      margin: 0;
    }
  }

  .form-control{
    display: block;
    width:100%;
    height: rem(38px);
    border-radius: rem(4px);
    background: cl(white);
    border:rem(1px solid rgb(214, 214, 214));
    padding:rem(0 16px);
    font-size:rem(15px);
    color:cl(text);
    @include placeholder( #bababa);
    &:focus {
      outline: none;
      box-shadow:none;
      background: cl(white);
      border-color:cl(primary);
    }
  }
}
.staff-page{
  .staff-grid{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: rem(120px);
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      column-gap: rem(70px);
    }
    .item{
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: rem(250px);
      margin-bottom: 2.5rem;
      &:hover{
        text-decoration: none;
        .item-details{
          .name {
            color: cl(secondary);
          }
        }
      }
      &-avatar{
        width: rem(180px);
        height: rem(180px);
        border-radius: 50%;
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: rem(20px);
      }
      &-details{
        font-size: rem(15px);
        font-weight: 500;
        text-align: center;
        color: cl(primary);
        line-height: rem(26px);
        padding: rem(0 10px);
        .name{
          font-size: rem(18px);
          line-height: rem(26px);
          font-weight: 700;
        }
        a{
          color: cl(primary);
        }

      }
    }
  }
}
.staff-ttl{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: rem(20px);
  overflow-x: hidden;
  h2{
    flex-shrink: 0;

  }
  .line{
    width: auto;
    background-color: rgb(206, 206, 206);
    height: 1px;
    margin-left: 1rem;
    flex-grow: 1;
    margin-bottom: rem(20px);
  }
}

.news-block-main{
  max-width: rem(900px);
  margin: 0 auto;
  .news-item{
    border-radius: rem(8px);
    background-color: rgb(255, 255, 255);
    box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
    width: 100%;
    font-size: rem(16px);
    line-height: rem(26px);
    padding: rem(15px 30px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: rem(10px);
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    .news-item-inner{
      padding-right: rem(25px);
      max-height: rem(85px);
      overflow-y: hidden;
      @include media-breakpoint-down(sm) {
        order:1;
        padding-right: 0;
        max-height: unset;
      }

    }
    .ttl{
      font-weight: 700;
      font-size: rem(20px);
      line-height: rem(30px);
      margin-bottom: rem(16px);
      color: cl(primary);
    }
    .news-item-img{
      flex-shrink: 0;
      @include media-breakpoint-down(sm) {
        margin: 0 auto;
      }
    }

    img{
      margin: 0!important;
      object-fit: cover!important;
      width: rem(120px)!important;
      height: rem(100px)!important;
      object-position: center;
      max-width: 100%!important;
      @include media-breakpoint-down(sm) {
        margin: 0 auto 1rem!important;
        width: rem(240px)!important;
        height: rem(200px)!important;
      }
    }
  }
}

.faq-section{
  //padding: rem(75px 0 80px 0);
  position: relative;

  .accordion-wrap{
    position: relative;
  }
  .faq-accordion{
    z-index: 5;
    position: relative;
  }
  .card {
    background: none;
    position: relative;
    margin: rem(0 0 13px);
    font-family: $font;
    border: 0;
    border-radius: 0;

    .card-header {
      background: none;
      padding: 0;

      border-bottom: 0;
      .head-link {
        border: 1px solid cl(primary);
        border-bottom: 0;
        border-bottom-right-radius: rem(0px);
        border-bottom-left-radius: rem(0px);
        &.collapsed {
          border: 1px solid #d1d6d9;
          border-bottom-right-radius: rem(8px);
          border-bottom-left-radius: rem(8px);
        }
      }
      button {
        font-size: rem(18px);
        line-height: rem(30px);
        margin: rem(0 0 0px);
        font-family: $font;
        font-weight: 700;
        color:cl(primary);
        background: cl(white);
        width:100%;
        display: block;
        border:0;
        padding: rem(20px 80px 20px 80px);
        position: relative;
        text-align: left;
        border-radius: rem(8px);
        @include media-breakpoint-down(sm) {
          font-size: rem(20px);
          line-height: rem(26px);
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(18px);
          line-height: rem(26px);
          padding: rem(14px 65px 14px 80px);
        }
        &:focus {
          outline: none;
        }

        &.collapsed {
          .plus-minus{

            &:after{
              opacity: 1;
            }
          }

        }
        @include media-breakpoint-down(xs) {
          font-size: rem(20px);
          line-height: rem(30px);
        }
        .plus-minus{
          position: absolute;
          right:rem(30px);
          top: 50%;
          transform: translateY(-50%) rotate(-180deg);
          transition: all .3s ease;
          width: rem(26px);
          height: rem(26px);
          @include media-breakpoint-down(xs) {
            width: rem(20px);
            height: rem(20px);
            right:rem(20px);
          }
          &:before{
            content: "";
            position: absolute;
            width: 100%;
            height: rem(2px);
            @extend .pos-centerY;
            left: 0;
            background-color: cl(secondary);
          }
          &:after{
            content: "";
            position: absolute;
            height: 100%;
            width: rem(2px);
            @extend .pos-centerX;
            background-color: cl(secondary);
            top: 0;
            opacity: 0;
            transition: 0.3s ease-in-out;
          }
        }
      }
      .logo{
        position: absolute;
        left:rem(20px);
        @extend .pos-centerY;
        transition: all .3s ease;
        z-index: 2;
        width:rem(30px);
        height: rem(30px);
        object-fit: cover;
        border-radius: 50%;
        @include media-breakpoint-down(xs) {
          width:rem(30px);
          height: rem(30px);
        }
      }
    }
    .collapse-body {
      border: 1px solid cl(primary);
      border-bottom-right-radius: rem(8px);
      border-bottom-left-radius: rem(8px);
      border-top: 0;

    }
    .card-body {
      background-color: cl(white);
      padding: rem(20px 46px 20px 80px);
      font-size: rem(16px);
      line-height: rem(26px);
      border:0;
      border-radius: 0;
      border-bottom-right-radius: rem(8px);
      border-bottom-left-radius: rem(8px);
      font-family: $font;
      color: cl(text);
      @include media-breakpoint-down(xs) {
        padding: rem(10px 26px 20px 20px);
      }
      p {
        margin: 0 0 1.5rem;
        font-size: rem(16px);
        line-height: rem(26px);
        @include media-breakpoint-down(xs) {
          font-size: rem(16px);
          line-height: rem(26px);
        }
      }
      &:focus {
        outline: none;
      }
      div{
        &:focus {
          outline: none;
        }
      }
      ul,ol{
        padding-left: 2rem;
      }

    }
  }
}

.board-meetings-block{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: rem(20px);
  flex-wrap: wrap;
  .item{
      border-radius: rem(8px);
      background-color: rgb(255, 255, 255);
      box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
      width: calc(50% - 1rem);
      font-size: rem(16px);
      line-height: rem(26px);
      color: cl(primary);
      padding: rem(32px 36px);
      overflow: hidden;
      margin-bottom: rem(20px);
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
      a{
        color: cl(primary);
        word-break: break-all;
      }
      .date{
        font-size: rem(18px);
        line-height: rem(28px);
        font-weight: 700;
        color: cl(primary);
      }
    .address{
      font-size: rem(16px);
      line-height: rem(26px);
      color: cl(primary);
      margin-bottom: rem(10px);
    }
    .zoom{
      color: cl(primary);
      margin-bottom: rem(10px);
    }
    .m-id{
      color: cl(primary);
    }
  }
}

.tia-event-block{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: rem(20px);
  flex-wrap: wrap;
  .item{
    border-radius: rem(8px);
    background-color: rgb(255, 255, 255);
    box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
    width: calc(50% - 1rem);
    font-size: rem(16px);
    line-height: rem(26px);
    color: cl(primary);
    padding: rem(32px 36px);
    overflow: hidden;
    margin-bottom: rem(20px);
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    .event-name{
      color: cl(primary);
      word-break: break-all;
      font-weight: 700;
      font-size: rem(20px);
    }
    .date{
      font-size: rem(16x);
      line-height: rem(28px);
      font-weight: 400;
      color: cl(primary);
    }
    .address{
      font-size: rem(16px);
      line-height: rem(26px);
      color: cl(primary);
      margin-bottom: rem(10px);
    }
    .zoom{
      color: cl(primary);
      margin-bottom: rem(10px);
    }
    .m-id{
      color: cl(primary);
    }
  }
}

.tia-slider{
  width: 100%;
  .item{
    img{
      max-width: 100% !important;
      max-height: 100vh !important;
      object-fit: contain;
     margin:0 !important;
    }
  }
}
.table-wrap{
  .table{
    tr{
      border: 0;
      th{
        font-weight: 700;
        font-size: rem(16px);
        color: cl(white);
        background-color: cl(primary);
        padding: rem(6px 0 6px 25px);
        &:first-child{
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
        }
        &:last-child{
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
      td{
        border: 0;
        border-bottom: 1px solid #e5e5e5;
        padding: rem(12px 0 12px 25px);
        font-size: rem(16px);
        p{
          margin-bottom: 0;
          word-break: break-all;
        }
      }
    }
  }
}

.events-page{
 // max-width: rem(900px);
  margin: 0 auto;
  padding-bottom: 4rem;
  .events-block{
    .event-item{
      border-radius: rem(8px);
      background-color: rgb(255, 255, 255);
      box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
      width: 100%;
      font-size: rem(16px);
      line-height: rem(26px);
      padding: rem(15px 30px);
      margin-bottom: rem(10px);
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
      .event-item-inner{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 30px 0 10px !important;
        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;
          padding: 40px 0 20px 0!important;
        }
        .event-separator {
          margin: 5px 0 10px;
          @include media-breakpoint-down(sm) {
            margin: 5px 0.75rem 10px 0.75rem;
          }
        }
      }
      .event-date-container {
        display: flex;
        @include media-breakpoint-down(sm) {
          flex-direction: column;
        }
      }
      .event-date{
        min-width: rem(69px);
        height: rem(45px);
        height: unset;
        @extend .ttu;
        font-size: rem(16px);
        line-height: rem(26px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-right: 2rem;
        @include media-breakpoint-down(sm) {
          height: unset;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding-right: 0;
        }
        span{
          font-size: rem(30px);
        }
      }
      .event-time{
        padding-right: 2rem;
      }
      .description{
        @include media-breakpoint-down(sm) {
          width: 100%!important;
          margin-top: 1rem;
          margin-left: 0!important;
        }
      }
      .add-to-calendar {
        position: absolute;
          right: 0 !important;
          font-size: 1.125rem;
          top: 0!important;
      }
      .ttl{
        font-weight: 700;
        font-size: rem(18px);
        line-height: rem(25px);
        margin-bottom: rem(16px);
        color: cl(primary);
      }
      .news-item-img{
        flex-shrink: 0;
        @include media-breakpoint-down(sm) {
          margin: 0 auto;
        }
      }
      p{
        margin-bottom: 0;
      }

    }
  }
  .event-top-line{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      margin-bottom: 2rem;
      justify-content: center;
    }
    .month-select-block{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include media-breakpoint-down(sm) {
        width: 50%;
        order: 1;
      }
      .month-select{
        margin-left: 1rem;
        a{
          color: cl(gray);
          &:hover{
            color: cl(primary);
          }
        }
      }
    }
    .fird{
      text-align: right;
      @include media-breakpoint-down(sm) {
        order: 1;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 1rem;
      }
    }
    ul{
      margin-bottom: 0;
      list-style: none;
      display: flex;
      align-items: center;
      li{
        &:first-child{
          padding-right: 1rem;
        }
        &:last-child{
          padding-left: 1rem;
        }
      }
    }
    h2{
      text-align: center;
      @include media-breakpoint-down(sm) {
        width: 100%;
        order: 0;
      }
    }
  }
}

.account-mobile-toggle{
  margin-bottom: 0.675rem;
  width: 100%;
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}